import React from 'react';

import InputText from '../InputText';

import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

const Radio = ({ customField, name, onChange, value, children }) => {
  const { fieldValues, setFieldValues, formErrors, setFormErrors, formSuccess, setFormSuccess } = useStateContext();
  
  const defaultOnChange = (e) => {
    if(onChange) {
      onChange(e);
    } else {
      setFieldValues({...fieldValues, [name]: e.target.value});
    }

    let newFormErrors = [...formErrors];
    let newFormSuccess = [...formSuccess];

    if(e.target.value !== '' && newFormErrors.includes(name)){
      newFormErrors = newFormErrors.filter(item => item !== name);
      if(!newFormSuccess.includes(name)){
        newFormSuccess.push(name);
      }
    }
    setFormErrors(newFormErrors);
    setFormSuccess(newFormSuccess);
  }

  return (
    <div className={styles['custom-radio']}>
      <label>
        <input type="radio" onChange={defaultOnChange} name={name} value={value} />
        <i></i>
        <span>{children}</span>
        {customField && (
          <InputText 
            customClass={styles['custom-radio__input']}
            name={name}
            placeholder="Insert your value"
          />
        )}
      </label>
    </div>
  )
}

export default Radio