import React, { useState, useRef, useEffect } from 'react';

import { Button, Checkbox } from '../';

import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

const Select = ({ placeholder, items, name, required }) => {
  const { fieldValues, setFieldValues, formErrors, setFormErrors, formSuccess, setFormSuccess } = useStateContext();

  const [open, setOpen] = useState(false);
  const [closing, setClosing] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredItems, setFilteredItems] = useState(items);
  const [selectedItems, setSelectedItems] = useState([]);
  const [otherInputValue, setOtherInputValue] = useState('');
  const [noResults, setNoResults] = useState(false);
  const [value, setValue] = useState(null);
  const customSelect = useRef(null);
  const otherInput = useRef(null);
  const otherCheckbox = useRef(null);

  const selectToggle = (e) => {
    e.preventDefault();
    if(open){
      setClosing(true);
      setTimeout(function(){
        setOpen(false);
      }, 500);
    } else {
      setClosing(false);
      setOpen(true);
    }
  };

  const close = (e) => {
    setClosing(true);
    setTimeout(function(){
      setOpen(false);
    }, 500);
  };

  const apply = (e) => {
    const newValue = [...selectedItems, otherInputValue].filter(item => item !== '').join(', ');
    if(newValue !== ''){
      setValue(newValue);
      setFieldValues({...fieldValues, [name]: newValue});
  
      let newFormErrors = [...formErrors];
      let newFormSuccess = [...formSuccess];
  
      if(newFormErrors.includes(name)){
        newFormErrors = newFormErrors.filter(item => item !== name);
        if(!newFormSuccess.includes(name)){
          newFormSuccess.push(name);
        }
      }
      setFormErrors(newFormErrors);
      setFormSuccess(newFormSuccess);
    } else {
      setValue(null);
    }

    close(e);
  }

  const closeOnOutside = (e) => {
    if(customSelect.current && open && !customSelect.current.contains(e.target)){
      apply(e);
    }
  };

  const search = (e) => {
    const value = e.target.value;
    const regex = new RegExp(value, 'gmi');

    setSearchValue(value);

    if(value.length > 2){
      const newFilteredItems = filteredItems.filter(item => item.search(regex) !== -1);
      setFilteredItems(newFilteredItems);
      setNoResults(newFilteredItems.length <= 0)
    } else {
      setFilteredItems(items);
      setNoResults(false);
    }
  };

  const onChange = (e) => {
    if(e.target.checked){
      setSelectedItems([...selectedItems, e.target.value]);
    } else {
      const newSelectedItems = selectedItems.filter(item => item !== e.target.value);
      setSelectedItems(newSelectedItems);
    }
  };

  const otherCheckboxOnChange = (e) => {
    if(e.target.checked){
      setOtherInputValue(otherInput.current.value);
      otherInput.current.focus();
    } else {
      setOtherInputValue('');
      otherInput.current.blur();
    }
  };

  const otherInputOnChange = (e) => {
    if(otherCheckbox.current.checked){
      setOtherInputValue(e.target.value);
    } else {
      setOtherInputValue('');
    }
  }

  const reset = (e) => {
    e.preventDefault();

    setSelectedItems([]);
    setSearchValue('');
    setOtherInputValue('');
    otherCheckbox.current.checked = false;
    setFilteredItems(items);
    setValue(null);
    close(e);
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOnOutside);
  
    return () => {
      document.removeEventListener("mousedown", closeOnOutside);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customSelect, open, selectedItems, otherInputValue]);

  return (
    <div 
      ref={customSelect}
      className={`${styles['custom-select-block']} ${open ? styles.opened : ''} ${closing ? styles.closed : ''}`}
    >
      <label className={`${styles['custom-select-block__label']} ${selectedItems.length > 0 ? styles['visible'] : ''}`}>{!fieldValues[required] ? placeholder : placeholder.slice(0, -1)}</label>
      <div 
        className={styles['custom-select__title']}
        onClick={e => selectToggle(e)} 
      >{value ? value : !fieldValues[required] ? placeholder : placeholder.slice(0, -1)}</div>
      <div className={styles['custom-select__dropdown']}>
        <div className={styles['custom-select__select-and-search']}>
          <div className={styles['custom-select__search']}>
            <input onChange={e => search(e)} type="text" placeholder="Search..." value={searchValue} />
            <i className="icon-search-2"></i>
          </div>
          <div className={styles['custom-select__checkboxes']}>
            {noResults && (
              <div className={styles['custom-select__no-results']}>No Results...</div>
            )}
            {filteredItems && filteredItems.length > 0 && filteredItems.map((item, index) => (
              <Checkbox
                key={index}
                onChange={e => onChange(e)}
                customClass={styles['custom-select__checkbox']}
                value={item}
                checked={selectedItems.includes(item)}
              >
                {item}
              </Checkbox>
            ))}
          </div>
        </div>
        <div className={styles['custom-select__other']} data-select-other>
          <div className={styles['custom-select__checkbox']}>
            <label>
              <input type="checkbox" ref={otherCheckbox} onChange={e => otherCheckboxOnChange(e)}/>
              <i></i>
              <span>Other</span>
              <div className={styles['custom-select__other__input']}>
                <input 
                  type="text" 
                  onChange={e => otherInputOnChange(e)} 
                  ref={otherInput} 
                  value={otherInputValue} 
                  placeholder="Type your answer" 
                />
              </div>
            </label>
          </div>
        </div>
        <div className={styles['custom-select__controls']}>
          <Button 
            type="text" 
            color="white"
            onClick={e => close(e)}
          >Cancel</Button>
          <Button 
            type="text" 
            color="white"
            onClick={e => reset(e)}
          >Reset</Button>
          <Button 
            type="text" 
            color="white" 
            customClass={styles['ml-auto']}
            onClick={e => apply(e)}
          ><strong>Apply</strong></Button>
        </div>
      </div>
    </div>
  )
}

export default Select