import React from 'react';

import { FooterLinks, FooterFeedback, FooterCopy } from '../../utils/data';

import styles from './index.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={`layout ${styles.layout}`}>
        <div className={styles.footer__top}>
          <div className={styles.footer__row}>
            {FooterLinks && FooterLinks.map(({title, links}, colIndex) => (
              <div key={colIndex} className={styles.footer__col}>
                <div className={styles.title}>{title}</div>
                {links && (
                  <div className={styles.footer__nav}>
                    {links.map(({href, title, newLink}, index) => (
                      <a key={index} href={href} className={styles['footer__nav-item'] + ' ' + (newLink ? styles['--new'] : '')}>{title}</a>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {(FooterFeedback || FooterCopy) && (
          <div className={styles.footer__bottom}>
            {FooterFeedback && (
              <div className="footer__feedback">
                {FooterFeedback.text} <a href={`mailto:${FooterFeedback.email}`}>{FooterFeedback.email}</a>
              </div>
            )}
            {FooterCopy && <div className="footer__copy">{FooterCopy}</div>}
          </div>
        )}
      </div>
    </footer>
  )
}

export default Footer