import React from 'react';

import styles from './index.module.scss';

const FormHeader = ({ icon, title }) => {
  return (
    <header className={styles.form__header}>
      <div className={styles.form__header__icon}>
        <i className={`icon-${icon}`}></i>
      </div>
      <h2>{title}</h2>
    </header>
  )
}

export default FormHeader