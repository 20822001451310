import React from 'react';

import styles from './index.module.scss';

const Tooltip = ({ customClass, children }) => {
  return (
    <div className={`${customClass} ${styles.tooltip} ${styles['--info']}`}>
      <i className={styles.tooltip__icon}></i>
      <div className={styles.tooltip__cnt}>
        <p>{children}</p>
      </div>
    </div>
  )
}

export default Tooltip