import React from 'react';

import styles from './index.module.scss';

const Search = () => {
  return (
    <div className={styles.header__search}>
      <i className={`icon-search ${styles['icon-search']}`}></i>
    </div>
  )
}

export default Search