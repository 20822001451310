import React, { useState } from 'react';

import { Checkbox, Button, FormStep, InputText, Select, Tooltip, Radio, FormHeader, FieldsGroup } from '../';

import { SelectSectorOptions, SelectEngagementTypeOptions, SelectCountryOptions, SelectRegionOptions, SelectLanguagesOptions, SelectOtherSolutionsOptions, SelectCapBuildingOptions, SelectTargetingOptions } from '../../utils/data';

import { useStateContext } from '../../context/StateContextProvider';

import styles from '../FormBlock/index.module.scss';

const FormBlock = ({ setShowThanksModal }) => {
  const { currentStep, setCurrentStep, fieldValues, formErrors, setFormErrors, formSuccess, setFormSuccess } = useStateContext();

  const formSteps = [
    {
      header: <FormHeader icon="gear" title="Client Background" />,
      fields: [{
        name: 'client-name',
        col: '6-12',
        el: <>
          <InputText name="client-name" required="client-name-confidential" disabled="client-name-confidential" placeholder="Client Name*" value="" />
          <div className={styles['form-block__confidential']}>
            <Checkbox customClass={styles['custom-checkbox']} name="client-name-confidential">Confidential</Checkbox>
          </div>
        </>,
        errorMsg: 'Please provide Client Name',
        required: 'client-name-confidential',
      },
      {
        name: 'sector',
        col: '6-12',
        el: <>
          <Select 
            name="sector"
            placeholder="Sector*"
            required="client-name-confidential"
            items={SelectSectorOptions}
          />
        </>,
        errorMsg: 'Please choose any Sector',
        required: 'client-name-confidential',
      },
      {
        name: 'engagement-type',
        col: '6-12',
        el: <>
          <Select 
            name="engagement-type"
            placeholder="Engagement type*"
            items={SelectEngagementTypeOptions}
          />
        </>,
        errorMsg: 'Please choose any Engagement type',
        required: true,
      },
      {
        name: 'phase',
        col: '6-12',
        el: <>
          <InputText name="phase" placeholder="Phase" value="" />
        </>,
        errorMsg: 'Please provide Phase',
      },
      {
        name: 'country',
        col: '6-12',
        el: <>
          <Select 
            name="country"
            placeholder="Country*"
            items={SelectCountryOptions}
          />
          <Tooltip>Country where the majority of the participants will be from</Tooltip>
        </>,
        errorMsg: 'Please choose any Country',
        required: true,
      },
      {
        name: 'work-academy',
        col: '6-12',
        el: <>
          <InputText name="work-academy" placeholder="Any work done with Academy previously (please indicate program)" value="" />
        </>,
        errorMsg: 'Please choose any item'
      },
      {
        name: 'region',
        col: '6-12',
        el: <>
          <Select 
            name="region"
            placeholder="Region*"
            items={SelectRegionOptions}
          />
          <Tooltip>Region that owns this opportunity</Tooltip>
        </>,
        errorMsg: 'Please choose any Region',
        required: true,
      },
      {
        name: 'desired-deployment-timeline',
        col: '6-12',
        fieldClass: styles['--label'],
        el: <>
          <div className={styles['form-block__label']}>
            <span>Desired deployment timeline</span>
            <Tooltip>When do you need to kick off the work?</Tooltip>
          </div>
          <div className={styles['form-block__radios']}>
            <Radio name="desired-deployment-timeline" value="less-4-weeks">Less than 4 weeks</Radio>
            <Radio name="desired-deployment-timeline" value="more-4-weeks">More than 4 weeks</Radio>
            <Radio name="desired-deployment-timeline" value="more" customField={true}>More</Radio>
          </div>
        </>,
        errorMsg: 'Please choose any item',
      },
      {
        name: 'languages',
        col: '6-12',
        el: <>
          <Select 
            name="languages"
            placeholder="Language(s) needed"
            items={SelectLanguagesOptions}
          />
        </>,
        errorMsg: 'Please choose any Language',
      },
      {
        name: 'other-solutions',
        col: '6-12',
        el: <>
          <Select 
            name="other-solutions"
            placeholder="What other solutions are being / will be deployed?"
            items={SelectOtherSolutionsOptions}
          />
        </>,
        errorMsg: 'Please choose any item',
      }]
    },{
      header: <FormHeader icon="scheme" title="Forecasted Impact" />,
      fields: [{
        name: 'success-for-client',
        el: <>
          <InputText name="success-for-client" placeholder="What does success look like for this client?" value="" />
        </>,
        errorMsg: 'Please fill in this field',
      }]
    },{
      header: <FormHeader icon="loading" title="CST" />,
      type: 'multiple',
      fields: [{
        name: 'cst-em',
        col: '4-12',
        el: (i) => <InputText name={`cst-em-${i}`} placeholder="CST EM" value="" />,
      },
      {
        name: 'cst-leader-ed',
        col: '4-12',
        el: (i) => <InputText name={`cst-leader-ed-${i}`} placeholder="CST Leader/ ED*" value="" />,
        errorMsg: 'Please fill in this field',
        required: true,
      },
      {
        name: 'dcs',
        col: '4-12',
        el: (i) => <InputText name={`dcs-${i}`} placeholder="DCS" value="" />,
      }]
    },{
      header: <FormHeader icon="human" title="Client need" />,
      fields: [{
        name: 'capability-building-needs',
        col: '9-12',
        el: <>
          <Select 
            name="capability-building-needs"
            placeholder="Which of the following capability building needs apply?*"
            items={SelectCapBuildingOptions}
          />
        </>,
        errorMsg: 'Please choose any item',
        required: true,
      },
      {
        name: 'targeting-cohorts',
        col: '3-12',
        el: <>
          <Select 
            name="targeting-cohorts"
            placeholder="Which cohorts are you targeting*"
            items={SelectTargetingOptions}
          />
        </>,
        errorMsg: 'Please choose any item',
        required: true,
      },
      {
        type: 'group',
        customClass: styles['--label'],
        label: <div className={styles['form-block__label']}>Reach <i>(rough estimate of number of participants in the journey)</i></div>,
        groupFields: SelectTargetingOptions.map((item) => {
          const name = `reach-${item.replace(/\s+/g, '-').toLowerCase()}`;
          return {
            name,
            label: item,
            field: <FieldsGroup name={name} label={item} errorMsg='Please fill in this field' />,
            required: true,
          }
        })
      },
      {
        name: 'learning-journey',
        el: <>
          <InputText
            name="learning-journey"
            placeholder="Any preference on learning journey that we need to be aware of (e.g. duration, in-person vs virtual)" 
            value=""
          />
        </>,
      }]
    }
  ];

  const [cstFields, setCstFields] = useState([[0],[0],[0]]);
  const steps = 4;
  const changeStep = () => {
    let newFormErrors = [...formErrors];
    let newFormSuccess = [...formSuccess];

    formSteps[currentStep-1].fields.forEach(({name, type, groupFields, required}, index) => {
      if(type === 'group'){
        groupFields.forEach((groupField) => {
          if(groupField.required && fieldValues['targeting-cohorts'] && fieldValues['targeting-cohorts'].includes(groupField.label)){
            if(groupField.name in fieldValues && fieldValues[groupField.name] !== ''){
              newFormErrors = newFormErrors.filter(item => item !== groupField.name);
              if(!newFormSuccess.includes(groupField.name)){
                newFormSuccess.push(groupField.name);
              }
            } else {
              newFormSuccess = newFormSuccess.filter(item => item !== groupField.name);
              if(!newFormErrors.includes(groupField.name)){
                newFormErrors.push(groupField.name);
              }
            }
          }
        });
      } else {
        if(typeof required === 'string'){
          if(!fieldValues[required]){
            if(name in fieldValues && fieldValues[name] !== ''){
              newFormErrors = newFormErrors.filter(item => item !== name);
              if(!newFormSuccess.includes(name)){
                newFormSuccess.push(name);
              }
            } else {
              newFormSuccess = newFormSuccess.filter(item => item !== name);
              if(!newFormErrors.includes(name)){
                newFormErrors.push(name);
              }
            }
          } else {
            newFormErrors = newFormErrors.filter(item => item !== name);
          }
        } else if(required === true){
          if(formSteps[currentStep-1].type === 'multiple'){
            cstFields[index].forEach((item, i) => {
              if(item !== undefined){
                const nameProp = name+'-'+i;
                if(nameProp in fieldValues && fieldValues[nameProp] !== ''){
                  newFormErrors = newFormErrors.filter(item => item !== nameProp);
                  if(!newFormSuccess.includes(nameProp)){
                    newFormSuccess.push(nameProp);
                  }
                } else {
                  newFormSuccess = newFormSuccess.filter(item => item !== nameProp);
                  if(!newFormErrors.includes(nameProp)){
                    newFormErrors.push(nameProp);
                  }
                }
              }
            })
          } else {
            if(name in fieldValues && fieldValues[name] !== ''){
              newFormErrors = newFormErrors.filter(item => item !== name);
              if(!newFormSuccess.includes(name)){
                newFormSuccess.push(name);
              }
            } else {
              newFormSuccess = newFormSuccess.filter(item => item !== name);
              if(!newFormErrors.includes(name)){
                newFormErrors.push(name);
              }
            }
          }
        }
      }
    });

    setFormErrors(newFormErrors);
    setFormSuccess(newFormSuccess);

    if(newFormErrors.length <= 0){
      if(currentStep === steps){
        setShowThanksModal(true);
      }
  
      if(currentStep < steps){
        setCurrentStep(currentStep + 1);
      }
    }
  };
  const prevStep = () => {
    if(currentStep > 1){
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <>
      <FormStep 
        header={formSteps[currentStep-1].header} 
        fields={formSteps[currentStep-1].fields}
        type={formSteps[currentStep-1].type}
        cstFields={cstFields}
        setCstFields={setCstFields}
        key={currentStep}
      />

      <div className={styles['form-block__button']}>
        {currentStep > 1 && (
          <Button 
            onClick={prevStep}
            color="black"
          >
            <i className="icon-angle --left"></i>
            <span className="text-wrapper">Previous</span>
          </Button>
        )}
        <Button onClick={changeStep}>
          <span className="text-wrapper">
            <span className={`text ${currentStep === steps ? '--submit' : ''}`}>
              <span>Next</span>
              <span>Submit</span>
            </span>
          </span>
          <i className="icon-angle"></i>
        </Button>
      </div>
    </>
  )
}

export default FormBlock