import React, { useState } from 'react';
import { Modal } from '@mui/material';

import { Button, Checkbox, InputText, Tooltip } from '../';
import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

const ModalWindow = ({ showModal, setShowModal }) => {
  const [referredByValues, setReferredByValues] = useState(['']);
  const { setReferredBy, referredYes, setReferredYes, modalNA, setModalNA } = useStateContext();

  const handleChange = (e, index) => {
    const newReferredByValues = [...referredByValues]
    newReferredByValues[index] = e.target.value;
    setReferredByValues(newReferredByValues);
  };

  const addName = (e) => {
    e.preventDefault();
    setReferredByValues([...referredByValues, '']);
  };

  const removeName = (e, index) => {
    e.preventDefault();

    const newReferredByValues = referredByValues.map((item, idx) => {
      if(idx === index) return undefined;
      return item;
    });
    setReferredByValues(newReferredByValues);
  };

  const saveRefferedBy = (e) => {
    e.preventDefault();

    if(referredYes){
      const newReferredByValues = referredByValues.filter(item => item !== '' && item !== undefined);
      setReferredBy(newReferredByValues);
    } else {
      setReferredBy([]);
    }
    setShowModal(false);
  };

  return (
    <Modal
      open={showModal}
    >
      <div className={`modal-window ${styles['modal--who-referred']}`}>
        <header>
          <div className={styles['who-referred__icon']}>
            <i className="icon-gear"></i>
          </div>
          <h2>Were you referred to this questionnaire? <Tooltip customClass={styles.tooltip}>If you were not referred check NA</Tooltip></h2>
        </header>
        <div className={styles['who-referred__form']}>
          <>
            <div className={styles['who-referred__checkboxes']}>
              <Checkbox onChange={() => setReferredYes(!referredYes)} checked={referredYes}>Yes</Checkbox>
              <Checkbox onChange={() => setModalNA(!modalNA)} checked={modalNA}>N/A</Checkbox>
            </div>
            {referredYes && referredByValues.map((item, index) => {
              if(item !== undefined){
                return (
                  <div className={styles['who-referred__field']} key={index}>
                    <InputText onChange={(e) => handleChange(e, index)} placeholder="Full name" value={item} />
                    {index !== 0 && (
                      <Button onClick={(e) => removeName(e, index)} customClass={styles['btn-circle']} type="circle"><i className="minus"></i></Button>
                    )}
                  </div>
                )
              }
              return '';
            })}
          </>
        </div>
        <div className={styles['who-referred__buttons']}>
          {referredYes && (
            <Button color="black" onClick={(e) => addName(e)}>
              <span>Add name (+)</span>
            </Button>
          )}
          <Button onClick={(e) => saveRefferedBy(e)} disabled={!(modalNA || (referredYes && !referredByValues.every(item => item === '')))}>
            <span>Continue</span>
            <i className="icon-angle"></i>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalWindow