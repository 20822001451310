import React from 'react';

import { useStateContext } from '../../context/StateContextProvider';
import { Button } from '../../components/';

import styles from '../FormBlock/index.module.scss';

const FormStep = ({header, fields, type, setCstFields, cstFields}) => {
  const { formErrors, formSuccess, fieldValues } = useStateContext();

  const addPerson = (e, index) => {
    e.preventDefault();
    const newCstFields = [...cstFields];
    newCstFields[index].push(newCstFields[index].length);
    setCstFields(newCstFields);
  };

  const removePerson = (e, fieldIndex, index) => {
    e.preventDefault();
    const newCstFields = [...cstFields].map((item, i) => {
      if(fieldIndex === i){
        return item.map((subItem) => {
          if(subItem === index) return undefined;
          return subItem;
        })
      } else {
        return item;
      }
    });
    setCstFields(newCstFields);
  };

  return (
    <div className={styles['form-block__block']}>
      {header}
      <div className={styles['form-block__fields']}>
        {type === 'multiple' && cstFields && (
          <div className={`${styles['form-block__grid']} ${styles.multiple}`}>
            {fields && fields.map(({col, el, name, errorMsg}, index) => (
              <div 
                key={index} 
                className={`${styles['form-block__col']} ${col ? styles['col-'+col] : ''}`}
              >
                {type === 'multiple' && cstFields && cstFields[index] && cstFields[index].map(i => {
                  if(i !== undefined){
                    return (
                      <div 
                        key={i}
                        className={`${styles['form-block__field']} ${formErrors && formErrors.includes(`${name}-${i}`) ? styles['--error'] : ''} ${formSuccess && formSuccess.includes(`${name}-${i}`) ? styles['--success'] : ''}`}
                      >
                        {el(i)}
                        {formErrors && formErrors.includes(`${name}-${i}`) && (<div className={styles['error-msg']}>{errorMsg}</div>)}
                        {i === 0 && (
                          <Button onClick={(e) => addPerson(e, index)} customClass={styles['btn-circle']} type="circle"><i className="plus"></i></Button>
                        )}
                        {i > 0 && (
                          <Button onClick={(e) => removePerson(e, index, i)} color="black" customClass={styles['btn-circle']} type="circle"><i className="minus"></i></Button>
                        )}
                      </div>
                    )
                  }
                  return '';
                })}
              </div>
            ))}
          </div>
        )}
        {(type !== 'multiple' || !cstFields) && (
          <div className={styles['form-block__grid']}>
            {fields && fields.map(({col, el, name, errorMsg, type, label, groupFields}, index) => (
              <React.Fragment key={index}>
                {type === 'group' && fieldValues['targeting-cohorts'] && (
                  <div className={`${styles['form-block__col']} ${col ? styles['col-'+col] : ''}`}>
                    <div className={`${styles['form-block__field']} ${formErrors && formErrors.includes(name) ? styles['--error'] : ''} ${formSuccess && formSuccess.includes(name) ? styles['--success'] : ''}`}>
                      <div className={styles['form-block__field-group']}>
                        {label}
                        {groupFields && groupFields.length > 0 && groupFields.map((item, index) => (
                          <React.Fragment key={index}>
                            {fieldValues['targeting-cohorts'].includes(item.label) && (
                              <>
                                {item.field}
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                {type !== 'group' && (
                  <div className={`${styles['form-block__col']} ${col ? styles['col-'+col] : ''}`}>
                    <div className={`${styles['form-block__field']} ${formErrors && formErrors.includes(name) ? styles['--error'] : ''} ${formSuccess && formSuccess.includes(name) ? styles['--success'] : ''}`}>
                      {el}
                      {formErrors && formErrors.includes(name) && (<div className={styles['error-msg']}>{errorMsg}</div>)}
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default FormStep