import React from 'react';

import { StepsStatus, StepsContent } from '../../utils/data';
import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

const Steps = () => {
  const { currentStep, formErrors } = useStateContext();

  return (
    <div className={styles.steps__block}>
      {StepsContent && StepsContent.map(({id, step, title}) => (
        <div 
          key={id} 
          className={`${styles.steps__item} ${id === currentStep && formErrors.length === 0 ? styles.active : ''} ${id === currentStep && formErrors.length > 0 ? styles.error : ''}`}
        >
          <div className={styles.steps__line}>
            <div className={styles['steps__line-inner']}></div>
          </div>
          <div className={styles.steps__icon}></div>
          <div className={styles.steps__cnt}>
            <div className={styles.steps__step}>{step}</div>
            <div className={styles.steps__title}>{title}</div>
            {StepsStatus && (
              <div className={styles.steps__status}>
                <div className={styles['status-pending']}>{StepsStatus.pending}</div>
                <div className={styles['status-in-progress']}>{StepsStatus.inProgress}</div>
                <div className={styles['status-completed']}>{StepsStatus.completed}</div>
                <div className={styles['status-error']}>{StepsStatus.error}</div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Steps