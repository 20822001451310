import React, { useState } from 'react';

import Search from '../Search';

import { HeaderLinks } from '../../utils/data';

import Logo from '../../assets/svg/logo.svg';

import styles from './index.module.scss';

const Header = () => {
  const [mobileHeaderOpen, setMobileHeaderOpen] = useState(false);
  
  const toggleOpen = (e) => {
    e.preventDefault();
    setMobileHeaderOpen(!mobileHeaderOpen);
  }

  return (
    <header className={styles.header}>
      <div className={`layout ${styles.layout}`}>
        <a href="/" className={styles.header__logo}>
          <img src={Logo} alt="McKinsey - Questionnaire" />
        </a>
        <div className={`${styles.header__nav} ${mobileHeaderOpen ? styles.opened : ''}`}>
          <a href="/" className={styles.header__logo + ' ' + styles['header__logo--mobile']}>
            <img src={Logo} alt="McKinsey - Questionnaire" />
          </a>
          <nav>
            {HeaderLinks && HeaderLinks.map(({link, title, active}, index) => (
              <a key={index} href={link} className={styles.header__nav__item + ' ' + (active ? styles.active : '')}>{title}</a>
            ))}
          </nav>
          <button className={styles['header__mobile-close']} onClick={e => toggleOpen(e)}></button>
        </div>
        <Search />
        <button className={styles['header__mobile-button']} onClick={e => toggleOpen(e)}>
          <i></i>
        </button>
      </div>
    </header>
  )
}

export default Header