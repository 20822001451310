import React from 'react';

import Breadcrumbs from '../Breadcrumbs';

import { HeroContent } from '../../utils/data';

import styles from './index.module.scss';

const Hero = () => {
  return (
    <section className={styles.hero__section}>
      <Breadcrumbs />
      <div className={`layout ${styles.layout}`}>
        <h1 className="--decorated">{HeroContent.h1}</h1>
        {HeroContent.cnt}
      </div>
    </section>
  )
}

export default Hero