import React, { useState } from 'react';

import { FormBlock, Hero, ReferredBy, Steps, ModalWindow, ModalThankYou } from '../../components';

import styles from './index.module.scss';

const Home = () => {
  const [showThanksModal, setShowThanksModal] = useState(false);
  const [showModal, setShowModal] = useState(true);

  return (
    <div className="Home">
      <ModalWindow showModal={showModal} setShowModal={setShowModal} />
      <ModalThankYou open={showThanksModal} setOpen={setShowThanksModal} />
      <Hero />
      <section className={styles.form__section}>
        <div className="layout">
          <form>
            <Steps />
            <ReferredBy setShowModal={setShowModal} />
            <FormBlock setShowThanksModal={setShowThanksModal} />
          </form>
        </div>
      </section>
    </div>
  )
}

export default Home