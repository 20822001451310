import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

export const StateContextProvider = ({ children }) => {
  const [referredBy, setReferredBy] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [formErrors, setFormErrors] = useState([]);
  const [formSuccess, setFormSuccess] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [referredYes, setReferredYes] = useState(false);
  const [modalNA, setModalNA] = useState(false);

  return (
    <StateContext.Provider value={{
      referredBy, setReferredBy,
      currentStep, setCurrentStep,
      formErrors, setFormErrors,
      fieldValues, setFieldValues,
      formSuccess, setFormSuccess,
      referredYes, setReferredYes,
      modalNA, setModalNA
    }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext);