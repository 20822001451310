import React from 'react';

import styles from './index.module.scss';

const Button = ({ element = 'button', href, customClass, color = 'blue', type = 'm', children, ...other }) => {
  return (
    <>
      {element === 'button' && (
        <button type="button" className={`${customClass} ${styles.btn} btn-${type} ${styles['btn-'+type]} ${styles['btn-'+color]}`} {...other}>
          {children}
        </button>  
      )}
      {element === 'a' && (
        <a href={href} className={`${customClass} ${styles.btn} btn-${type} ${styles['btn-'+type]} ${styles['btn-'+color]}`} {...other}>
          {children}
        </a>  
      )}
    </>
  )
}

export default Button