import React, { useState } from 'react';

import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

const InputText = ({ customClass, onChange, placeholder, value, name, required, disabled }) => {
  const { fieldValues, setFieldValues, formErrors, setFormErrors, formSuccess, setFormSuccess } = useStateContext();
  const [fieldValue, setFieldValue] = useState(value || '');

  const defaultOnChange = (e) => {
    if(onChange) {
      onChange(e);
    } else {
      setFieldValues({...fieldValues, [e.target.name]: e.target.value});
    }
    setFieldValue(e.target.value);

    let newFormErrors = [...formErrors];
    let newFormSuccess = [...formSuccess];

    if(e.target.value !== '' && newFormErrors.includes(name)){
      newFormErrors = newFormErrors.filter(item => item !== name);
      if(!newFormSuccess.includes(name)){
        newFormSuccess.push(name);
      }
    }
    setFormErrors(newFormErrors);
    setFormSuccess(newFormSuccess);
  }
  
  return (
    <div className={`${customClass ? customClass : ''} ${styles['form-block__input']} ${fieldValues[disabled] ? styles.disabled : ''}`}>
      <label className={`${styles['form-block__label']} ${fieldValue !== '' ? styles['visible'] : ''}`} htmlFor={`id_${name}`}>{!fieldValues[required] ? placeholder : placeholder.slice(0, -1)}</label>
      <input
        id={`id_${name}`}
        name={name}
        onChange={defaultOnChange}
        placeholder={!fieldValues[required] ? placeholder : placeholder.slice(0, -1)}
        type="text"
        value={fieldValue}
        disabled={fieldValues[disabled] === 'true' || fieldValues[disabled] === true}
      />
    </div>
  )
}

export default InputText