import React from 'react';
import { Modal } from '@mui/material';

import { Button } from '../';

import styles from './index.module.scss';

const ModalThankYou = ({ open }) => {
  return (
    <Modal
      open={open}
    >
      <div className={`modal-window ${styles['modal--thank-u']}`}>
        <div className={styles['thank-u__block']}>
          <div className={styles['thank-u__icon']}>
            <i className={`icon-check ${styles['icon-check']}`}></i>
          </div>
          <h2><strong>Capability Building</strong> needs assessment</h2>
          <p>Thank you for filling out the questionnaire. We will be in contact.</p>
          <Button 
            element="a" 
            href="https://popnavigator.intranet.mckinsey.com/assets-tools/mckinsey-academy" 
            customClass={`${styles.btn} ${styles['btn-m']}`}
          >
            <span>Complete</span>
            <i className="icon-angle"></i>
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ModalThankYou