import { Footer, Header } from './components';
import Home from './pages/Home';

import './App.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <Home />
      </main>
      <Footer />
    </div>
  );
}

export default App;
