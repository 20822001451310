import React from 'react';

import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

const Checkbox = ({ customClass, value = true, onChange, checked, name, children }) => {
  const { fieldValues, setFieldValues, formErrors, setFormErrors, formSuccess, setFormSuccess } = useStateContext();
  
  const defaultOnChange = (e) => {
    if(onChange) {
      onChange(e);
    } else {
      if(e.target.checked){
        setFieldValues({...fieldValues, [name]: e.target.value});
      } else {
        setFieldValues({...fieldValues, [name]: false});
      }
    }

    let newFormErrors = [...formErrors];
    let newFormSuccess = [...formSuccess];

    if(e.target.value !== '' && newFormErrors.includes(name)){
      newFormErrors = newFormErrors.filter(item => item !== name);
      if(!newFormSuccess.includes(name)){
        newFormSuccess.push(name);
      }
    }
    setFormErrors(newFormErrors);
    setFormSuccess(newFormSuccess);
  }

  return (
    <div className={`${customClass} ${styles['custom-checkbox']}`}>
      <label>
        <input type="checkbox" value={value} checked={checked} onChange={defaultOnChange} />
        <i></i>
        <span>{children}</span>
      </label>
    </div>
  )
}

export default Checkbox