import React from 'react';

import { BreadcrumbsLinks } from '../../utils/data';

import styles from './index.module.scss';

const Breadcrumbs = () => {
  return (
    <>
      {BreadcrumbsLinks && (
        <div className={styles.breadcrumbs}>
          <div className={`layout ${styles.layout}`}>
            {BreadcrumbsLinks.map(({link, title, active}, index) => (
              <div key={index}>
                {active ? (
                  <span className={styles.breadcrumbs__item}>{title}</span>  
                ) : (
                  <a href={link} className={styles.breadcrumbs__item}>{title}</a>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Breadcrumbs