import React from 'react';

import { Checkbox, InputText } from '../';

import { useStateContext } from '../../context/StateContextProvider';

import styles from './index.module.scss';

const FieldsGroup = ({ label, name, errorMsg }) => {
  const { formErrors, formSuccess } = useStateContext();

  return (
    <div className={styles['form-block__field-group']}>
      <div className={styles['field-group']}>
        <div className={styles['field-group__label']}>{label}</div>
        <div className={`${styles['field-group__field']} ${formErrors && formErrors.includes(name) ? styles['--error'] : ''} ${formSuccess && formSuccess.includes(name) ? styles['--success'] : ''}`}>
          <div className={styles['field-group__text-and-checkbox']}>
            <InputText 
              name={name}
              customClass={styles['field-group__input']}
              placeholder="Insert your value"
            />
            <Checkbox name={name} value="not sure">Not sure</Checkbox>
          </div>

          {formErrors && formErrors.includes(name) && (<div className={styles['error-msg']}>{errorMsg}</div>)}
        </div>
      </div>
    </div>
  )
}

export default FieldsGroup