import React from 'react';

import { useStateContext } from '../../context/StateContextProvider';
import styles from './index.module.scss';

const ReferredBy = ({ setShowModal }) => {
  const { referredBy } = useStateContext();

  return (
    <>
      {referredBy && referredBy.length > 0 && (
        <div className={styles['form__referred-by']}>
          <div className={styles['referred-by__icon']} onClick={() => setShowModal(true)}>
            <i className={`icon-edit ${styles['icon-edit']}`}></i>
          </div>
          <div className={styles['referred-by__text']}>
            <span>Referred by:</span> <strong>
              {referredBy.map((item, index) => (
                <span key={index}>{item}{index < referredBy.length-1 && (<>, </>)}</span>
              ))}
            </strong>
          </div>
        </div>
      )}
    </>
  )
}

export default ReferredBy